import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import Craft from './plugins/CraftApi'
import VueMeta from 'vue-meta'
import '@/plugins/ScrollTo'

Vue.config.productionTip = false

Vue.use(Craft)

Vue.use(VueMeta, {
    refreshOnceOnNavigation: true,
    debounceWait: 500,
})

new Vue({
    router,
    store,
    i18n,
    Craft,
    render: h => h(App),
}).$mount('#app')
