<template>
  <header class="navbar">

    <img src="@/assets/img/logo.png" class="logo" alt="Logo Studio MELS">

    <nav class="main-nav">
      <a href="#about" v-scroll-to:-80>{{ globalsIsLoaded ? globals.mainNav.links[0].label : $t('Studio') }}</a>
      <a href="#techspecs" v-scroll-to:-80>{{ globalsIsLoaded ? globals.mainNav.links[1].label : $t('Techspecs') }}</a>
      <a href="#technology" v-scroll-to:-80>{{ globalsIsLoaded ? globals.mainNav.links[2].label : $t('Essentials') }}</a>
      <a href="#faq" v-scroll-to:-80>{{ globalsIsLoaded ? globals.mainNav.links[3].label :  'FAQ'}}</a>
      <a href="#work" v-scroll-to:-80>{{ globalsIsLoaded ? globals.mainNav.links[4].label : $t('Work') }}</a>
      <a href="#gallery" v-scroll-to:-80>{{ globalsIsLoaded ? globals.mainNav.links[5].label : $t('Gallery') }}</a>
    </nav>

    <div class="right">
      <a href="https://www.melsproductionvirtuelle.com/fr" class="lang" v-if="$route.meta.lang === 'en'">FR</a>
      <a href="https://www.melsvirtualproduction.com" class="lang" v-if="$route.meta.lang === 'fr'">EN</a>
      <a href="#contact" class="cta" v-scroll-to:-80><p>{{ globalsIsLoaded ? globals.mainNav.callToAction : $t('Contact us') }}</p></a>
    </div>
  </header>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  computed: {
        ...mapState(['globalsIsLoaded']),
        ...mapGetters(['globals']),
  },
  methods: {
    toogleNavBarStatus() {
      const st = document.documentElement.scrollTop

      if (!document.querySelector('.navbar')) {
        return
      }

      if( st >= 40 ) {
        document.querySelector('.navbar').classList.add('sticky')
      }else{
        document.querySelector('.navbar').classList.remove('sticky')
      }
    }
  },
  created() {
    // NavBar Follow
    this.toogleNavBarStatus()
    window.addEventListener('scroll', () => {
      this.toogleNavBarStatus()
    })
  },
}
</script>

<style lang="scss" scoped>
</style>
