<template>
    <div id="app">
        <nav-bar></nav-bar>
        <transition name="fade" mode="out-in">
            <div class="main-wrapper">
                <router-view />
            </div>
        </transition>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import NavBar from '@/components/NavBar'

export default {
    computed: {
        ...mapGetters(['seo']),
    },

    watch: {
        $route: {
            handler($route) {
                if ($route) {
                    if (window.location.href === 'https://melsproductionvirtuelle.com/') {
                        window.location.href = 'https://melsproductionvirtuelle.com/fr'
                    }

                    if (window.location.href === 'https://melsproductionvirtuelle.com') {
                        window.location.href = 'https://melsproductionvirtuelle.com/fr'
                    }

                    if (window.location.href === 'https://melsvirtualproduction.com/fr') {
                        window.location.href = 'https://melsproductionvirtuelle.com/fr'
                    }

                    if (window.location.href === 'https://melsvirtualproduction.com/fr/') {
                        window.location.href = 'https://melsproductionvirtuelle.com/fr'
                    }
                }
            },
            immediate: true,
        },
    },

    components: {
        NavBar,
    },

    metaInfo() {
        return {
            ...(this.seo.titleRaw && this.seo.title && { title: this.seo.title }),
            ...(this.seo.titleRaw && { titleTemplate: '%s ' + this.seo.titleRaw[2] }),

            meta: [
                {
                    vmid: 'og:title',
                    property: 'og:title',
                    content: this.seo.title,
                },

                {
                    vmid: 'description',
                    name: 'description',
                    content: this.seo.description,
                },
                {
                    vmid: 'og:description',
                    property: 'og:description',
                    content: this.seo.description,
                },
            ],
            link: [
                {
                    vmid: 'canonical',
                    rel: 'canonical',
                    href: window.location.href,
                },
            ],
        }
    },
}
</script>

<style lang="scss">
@import '@/assets/styles/main.scss';

.fade-enter-active,
.fade-leave-active {
    transition-duration: 0.555s;
    transition-property: opacity, transform;
    transition-timing-function: cubic-bezier(0.35, 0, 0.22, 1);
}

.fade-enter,
.fade-leave-active {
    opacity: 0;
    transform: scale(1.0125) rotate(0.525deg);
}
</style>
