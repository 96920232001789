/**
 * CraftApi plugin
 * Please use in coordination with the Element API plugin
 * https://github.com/craftcms/element-api
 */

import axios from 'axios'
import i18n from '../i18n'

const ELEMENT_TYPE = 'pages'
const defaultLang = 'en'

let urlToken = ''

// Get the preview token from the URL
let m = document.location.href.match(/\bx-craft-live-preview=([^&]+)/)
let token = m ? m[1] : ''

let m2 = document.location.href.match(/\btoken=([^&]+)/)
let token2 = m2 ? m2[1] : ''

if (token) {
    // Then forward that on whenever you are sending an API request
    urlToken = `?x-craft-live-preview=${token}&token=${token2}`
}

const apiRootUrl = {
    'en': 'https://melsvirtualproduction.com',
    'fr': 'https://melsproductionvirtuelle.com',
}

class CraftApi {
    constructor() {
        this.baseUrl = '/api'
    }

    /**
     *
     * Gets any entry by slug, and defaults to pages
     *
     * @param slug
     * @param type
     *
     * @return object
     *
     */
    async getContentBySlug(slug, type = ELEMENT_TYPE, lang = i18n.locale) {
        let response

        const locale = lang === defaultLang ? '' : '/' + lang

        await axios
            .get(
                `${apiRootUrl[lang] + locale + this.baseUrl}/${type}/${slug}.json${urlToken}`
            )
            .then(res => {
                response = res
            })
            .catch(() => {
                response = {
                    code: 500,
                    message: 'An error occured',
                    page: null,
                }
            })

        return response
    }

    /**
     *
     * Gets all the globals
     *
     *
     * @return object
     *
     */
    async getGlobals() {
        let response = []
        const locale = i18n.locale === defaultLang ? '' : '/' + i18n.locale

        await axios
            .get(`${apiRootUrl[i18n.locale] + locale + this.baseUrl}/mainNav.json`)
            .then(res => {
                response['mainNav'] = res.data
            })
            .catch(() => {
                response = {
                    code: 500,
                    message: 'An error occured',
                    page: null,
                }
            })

        await axios
            .get(`${apiRootUrl[i18n.locale] + locale + this.baseUrl}/contact.json`)
            .then(res => {
                response['contact'] = res.data
            })
            .catch(() => {
                response = {
                    code: 500,
                    message: 'An error occured',
                    page: null,
                }
            })

        await axios
            .get(`${apiRootUrl[i18n.locale] + locale + this.baseUrl}/footer.json`)
            .then(res => {
                response['footer'] = res.data
            })
            .catch(() => {
                response = {
                    code: 500,
                    message: 'An error occured',
                    page: null,
                }
            })

        return response
    }

    /**
     *
     * Gets everything for debug purposes
     * Enabled only in development
     *
     * @return object
     *
     */
    async getAllEntries() {
        let response

        if (process.env.NODE_ENV === 'production') {
            return {
                code: 403,
                message: 'Forbidden',
            }
        }

        const LOCALE = i18n.locale === defaultLang ? '' : '/' + i18n.locale

        await axios
            .get(`${apiRootUrl[i18n.locale] + LOCALE + this.baseUrl}/debug.json`)
            .then(res => {
                response = res
            })
            .catch(() => {
                response = {
                    code: 500,
                    message: 'An error occured',
                    page: null,
                }
            })

        return response
    }
}

export default {
    install(Vue) {
        Vue.prototype.$craft = new CraftApi()
    },
}
